<template>
  <div>
    <div>
      <div class="row">
        <div class="col-9">
          <label for="exampleFormControlSelect1"
            ><h5>
              *
              <span
                >셀원 목록<span style="font-size: 12px">(가나다순)</span></span
              >
            </h5></label
          >
        </div>
        <div class="col-3" style="text-align: right">
          <span
          v-show="$store.state.grade > 4"
            ><button
              type="button"
              class="btn btn-primary btn-sm"
              @click="AddMember"
            >
              등록
            </button>
          </span>
        </div>
      </div>

      <div>
        <select
          class="form-control"
          id="gradegroup"
          name="gradegroup"
          v-model="selectleader"
          @change="getMgmtAllList()"
          v-show="this.$store.state.grade > 3 "
        >
          <option id="" value="00">셀 리더를 선택하세요.</option>
          <option
            v-for="gradelist in gradegrouplist"
            :key="gradelist.studentid"
            v-bind:value="gradelist.studentid"
            :id="gradelist.studentid"
          >
            {{ gradelist.name }}  {{ gradelist.rolename }}
          </option>
        </select>
      </div>
      <p></p>
      <div class="tab-content" id="myTabContent">
        <!--마을(팀)검색 -->
        <div
          class="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <!-- 리스트 시작 -->
          <div
            v-if="getManageList.length > 0"
            class="accordion"
            id="accordionExample"
          >
            <div
              class="card"
              v-for="manageList in getManageList"
              :key="manageList.no"
            >
              <div class="card-header" :id="'headingOne' + manageList.no">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left"
                    type="button"
                    data-toggle="collapse"
                    :data-target="'#collapseOne' + manageList.no"
                    aria-expanded="true"
                    :aria-controls="'#collapseOne' + manageList.no"
                    style="text-decoration-line: none"
                  >
                    <!-- <span style="color: #1d2124">
                         </span
                      ><span style="color: #dee2e6">|</span> -->
                    {{ manageList.name }}({{  getLastFourDigits(manageList.phone) }})
                    <span style="color: #dee2e6">|</span>
                  </button>
                </h2>
              </div>
              <div
                :id="'collapseOne' + manageList.no"
                class="collapse"
                :aria-labelledby="'headingOne' + manageList.no"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <ul class="list-group">
                    <li class="list-group-item" style="background: ghostwhite">
                      <div class="row">
                        <div class="col" style="font-size: 20px">
                          <b>{{ manageList.name }} </b>
                        </div>
                        <div
                         v-if="manageList.photo2 && manageList.photo2.length > 0"
                          class="col"
                          style="text-align: right"
                          @click="updatePhoto(manageList)"
                        >
                          <img
                            :src="photourl(manageList.photo2)"
                            alt="..."
                            class="img-thumbnail"
                            style="width: 35%"
                          />                     
                        </div>                        
                        <div
                          v-else-if="manageList.photo && manageList.photo.length > 0"
                          class="col"
                          style="text-align: right"
                          @click="updatePhoto(manageList)"
                        >
                          <img
                            :src="photourl(manageList.photo)"
                            alt="..."
                            class="img-thumbnail"
                            style="width: 36%"
                          />
                        </div>
                        <div
                          v-else
                          class="col"
                          style="text-align: right"
                          @click="updatePhoto(manageList)"
                        >
                          <img src="../assets/noimage.png" style="width: 20%" />
                          <p style="font-size: 12px;">사진 등록</p>                                
                        </div>                        
                      </div>
                    </li>
                    <li class="list-group-item">
                      <b>1. 생년월일:</b>
                      {{ manageList.birthday }}
                    </li>
                    <li class="list-group-item">
                      <b>2. 성별:</b>
                      {{ manageList.sex }}
                    </li>                    
                    <li class="list-group-item">
                      <b>3. 연락처</b>: {{ manageList.phone }}
                    </li>
                    <!-- <li class="list-group-item">3. 전파자: {{manageList.spreader}}</li> -->                  
                    <li class="list-group-item">
                      <b>4. 메모</b>: {{ manageList.rmk }}
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col" style="text-align: right">
                          <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            @click="UpdateMember(manageList)"
                          >
                            수정
                          </button>
                          <!-- <button type="button"  class="btn btn-warning btn-sm"  @click="updatePhoto(manageList)" style="margin-left: 13px;">사진등록</button> -->
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="accordion" id="accordionExample">
            <div class="card">
              <h2 class="mb-0">
                <button class="btn btn-block text-left">
                  조회된 셀원 자료가 없습니다.
                </button>
              </h2>
            </div>
          </div>
          <!-- 리스트 종료 -->
          <!-- pgaination -->
          <!-- <div style="padding-top: 20px">
              <paginate
                :pageCount="pageTotalCount"
                :clickHandler="clickCallback"
                :prevText="'Prev'"
                :nextText="'Next'"
                :containerClass="'pagination'"
              >
              </paginate>
            </div> -->
          <!-- pgaination -->
        </div>
      </div>
    </div>
    <!-- 등록/수정 -->
    <div
      class="modal fade"
      id="memberAddModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="memberAddModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              id="memberAddModalLabel"
              style="color: blue"
            >
              <span id="plpmo"></span>
              <div v-if="isNew">
                <p>셀원 신규 등록</p>
                <p style="color:red; font-size: 13px;">개인정보 동의가 필요합니다.가입화면을 이용해주세요.</p>
                <p style="color:black; font-size: 13px;">개인정보 미동의자에 한해서 등록해주세요.</p>
              </div>
              <div v-else>
                <p>셀원 정보 수정</p>
              </div>
            </h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <form
                id="addMemberform"
                name="addMemberform"
                v-on:submit="addMemberForm"
              >            
                <!-- 이름 -->
                <div class="form-group mx-sm-3 mb-2">
                  <div class="row" style="align-items: baseline">
                    <div class="col-3">
                      <h6>이름</h6>
                    </div>
                    <div class="col-9" v-if="$store.state.grade > 3">
                      <input
                        type="text"
                        class="form-control"
                        id="formname"
                        v-model="form.name"
                        name="formname"
                        autocomplete="off"
                        placeholder=""
                        style="width: 60%"                        
                      />
                    </div>
                    <div class="col-9" v-else>
                      <input
                        type="text"
                        class="form-control"
                        id="formname"
                        v-model="form.name"
                        name="formname"
                        autocomplete="off"
                        placeholder=""
                        style="width: 60%"                        
                        readonly
                      />
                    </div>                    
                  </div>
                </div>

                <!-- 성별 -->
                <div class="form-group mx-sm-3 mb-2">
                  <div class="row" style="align-items: baseline">
                    <div class="col-3">
                      <h6>성별</h6>
                    </div>
                    <div class="col-9">
                      <select
                        class="custom-select"
                        id="formsex"
                        name="formsex"
                        v-model="form.sex"
                        style="width: 40%"
                      >
                        <option value="남">남</option>
                        <option value="여">여</option>
                      </select>
                    </div>
                  </div>
                </div>

                <!-- 생년월일 -->
                <div v-if="!isNew" class="form-group mx-sm-3 mb-2">
                  <div class="row" style="align-items: baseline">
                    <div class="col-3">
                      <h6>출생년월</h6>
                    </div>
                    <div class="col-9">
                      <input
                        type="text"
                        class="form-control"
                        id="formbirthday"
                        v-model="form.birthday"
                        name="formmemberno"
                        autocomplete="off"
                        placeholder="ex) 20040506"
                        style="width: 60%"
                      />
                    </div>
                  </div>
                </div>
                <div v-else class="form-group mx-sm-3 mb-2">
                  <div class="row" style="align-items: baseline">
                    <div class="col-3">
                      <h6>출생년도</h6>
                    </div>
                    <div class="col-9">
                      <input
                        type="text"
                        class="form-control"
                        id="formbirthday"
                        v-model="form.birthday"
                        name="formmemberno"
                        autocomplete="off"
                        placeholder="ex) 2004"
                        style="width: 60%"
                      />
                    </div>
                  </div>
                </div>                
                <!-- 연락처 -->
                <div  v-if="!isNew" class="form-group mx-sm-3 mb-2">
                  <div class="row" style="align-items: baseline">
                    <div class="col-3">
                      <h6>연락처</h6>
                    </div>
                    <div class="col-9">
                      <input
                        type="text"
                        class="form-control"
                        id="formphone"
                        v-model="form.phone"
                        name="formphone"
                        autocomplete="off"
                        placeholder="010-0000-0000"
                        style="width: 60%"
                      />
                    </div>
                  </div>
                </div>
                <!-- 참고사항 -->
                <div class="form-group mx-sm-3 mb-2">
                  <div class="row" style="align-items: baseline">
                    <div class="col-3">
                      <h6>메모</h6>
                    </div>
                    <div class="col-9">
                      <textarea
                        type="text"
                        class="form-control"
                        id="formrmk"
                        name="formrmk"
                        v-model="form.rmk"
                        autocomplete="off"
                        placeholder=""
                        rows="3"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <div class="container">
                    <div class="row">
                      <div class="col-sm">
                        <button
                          type="button"
                          class="btn btn-secondary btn-sm"
                          data-toggle="modal"
                          data-target="#memberDeleteModal"
                        >
                          탈퇴처리
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          v-if="isNew"
                          @click="setMember(1)"
                          style="margin-left: 20px"
                        >
                          등록
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          v-else
                          @click="setMember(2)"
                          style="margin-left: 20px"
                        >
                          수정
                        </button>
                        <button
                          type="button"
                          class="btn btn-secondary btn-sm"
                          data-dismiss="modal"
                          @click="modalClose"
                          style="margin-left: 20px"
                        >
                          닫기
                        </button>
                      </div>                    
                    </div>
                    <div v-if="!isNew">
                        <p style="color:black; font-size: 13px;     padding-top: 15px;">※ 이름 변경은 관리자에게 요청해주세요.</p>
                      </div>                      
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 사진 등록 -->
    <div
      class="modal fade"
      id="memberPhotoModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="memberPhotoModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              id="memberPhotoModalLabel"
              style="color: blue"
            >
              <span id="plpmo"></span>
              <div>{{ photoitem.name }} 사진 등록</div>
            </h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <!-- 선생님 -->
              <!-- 참고사항 -->
              <div
                v-if="photoitem.photo && photoitem.photo.length > 0"
                class="form-group mx-sm-3 mb-2"
              >
                <img
                  :src="photourl(photoitem.photo)"
                  alt="..."
                  class="img-thumbnail"
                />
              </div>
              <div
                v-else
                class="form-group mx-sm-3 mb-2"
                style="text-align: center"
              >
                <img src="../assets/noimage.png" style="width: 20%" />
              </div>
              <div class="mb-3">
                <form ref="addFileForm">
                  <input
                    type="file"
                    ref="files"
                    @change="fileUpload"
                    class="form-control"
                    style="height: 45px"
                  />

                  <div v-if="fileArray.length > 0">
                    <div
                      class="ml-2 mt-1 d-flex justify-content-between"
                      v-for="(data, index) in fileArray"
                      :key="'file' + index"
                    ></div>
                  </div>
                  <span style="font-size: 12px;">※ 사진 방향을 맞추어 등록해주세요. 삭제 대신 다른 사진으로 등록해주세요.</span>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="savePhoto()"
                >
                  저장
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  @click="modalClose"
                >
                  닫기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 탈퇴 모달 -->
    <div
      class="modal fade"
      id="memberDeleteModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">탈퇴 처리</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">탈퇴 처리하시겠습니까?</div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary btn-sm"
              data-dismiss="modal"
            >
              닫기
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              @click="removemember(form)"
            >
              탈퇴 요청
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import manageService from "../services/manageService";
  //import moment from "moment";
  import $ from "jquery";
  import { mapState } from "vuex";

  export default {
    name: "list",
    components: {},
    data() {
      return {
        manageLists: {},
        page: 40, //보여줄 항목
        current_page: 0,
        manageListLimit: {},
        pageTotalCount: 0,
        form: {
          name: "",
          sex: "",
          birthday: "",
          phone: "",
          groupid: "",
          gradediv: "",
          parentphone: "",
          address: "",
          rmk: "",
          school: "",
          no: "",
          teacherid: this.$store.state.teacherid,
          grade: this.$store.state.grade,
          classid: this.$store.state.classid,
        },
        isNew: true,
        teachers: {},
        photoitem: {},
        file: "",
        fileArray: [],
        id: this.$store.state.id,
        grade: this.$store.state.grade,
        phone: this.$store.state.phone,
        name: this.$store.state.name,
        teacherid: this.$store.state.teacherid,
        role: this.$store.state.role,
        token: this.$store.state.token,
        classid: this.$store.state.classid,
        studentid: this.$store.state.studentid,
        gradegrouplist: {},
        gradegroup: "00",
        selectleader : "00",        
      };
    },
    computed: {
      ...mapState(["isLogin", "userInfo"]),
      getManageList: function () {
        return this.manageLists;
      },

      photourl() {
        return (url) => {
          return "./upload/photo/" + url;
        };
      },
    },
    methods: {

      //전화번호 뒤4자리만
      getLastFourDigits(tel) {
        return tel ? tel.slice(-4) : '';
      }, 
    //리더 출력
    ReaderGetListAll() {
      const ndata = {
        classid: this.classid,
        grade: this.gradegroup,
        role: this.role,
        teacherid : this.teacherid,
      };

      manageService
        .getCellReaderList(ndata)
        .then((response) => {
          this.gradegrouplist = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

      removemember(fform) {
        const deldata = {
          no: fform.no,
          name: fform.name,
          phone: fform.phone,
        };

        manageService
          .memberDelete(deldata)
          .then((response) => {
            if (response.data) {
              this.$toast.show("탈퇴 처리되었습니다.", {
                type: "success",
                position: "top-right",
              });
            } else {
              this.$toast.show("취소가 실패했습니다.", {
                type: "warning",
                position: "top-right",
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });

        $("#memberDeleteModal").modal("hide");
        $("#memberAddModal").modal("hide");
        this.getMgmtAllList(this.gradegroup);
      },
      getGradeGroup() {
        this.getMgmtAllList(this.gradegroup);
      },
      // getGradeGroupList() {
      //   const ndata = {
      //     classid: this.classid,
      //   };

      //   // 학년 반을 선택하세요.
      //   manageService
      //     .getGradeListAll(ndata)
      //     .then((response) => {
      //       //this.manageLists = response.data;
      //       this.gradegrouplist = response.data;
      //       console.log(response.data);
      //     })
      //     .catch((e) => {
      //       console.log(e);
      //     });
      // },
      fileUpload(event) {
        this.fileArray.push(event.target.files[0]);
      },
      updatePhoto(item) {
        //사진 팝업
        this.photoitem = item;
        //this.$emit('memberPhotoModal',item);
        $("#memberPhotoModal").modal("show");
      },

      savePhoto() {
        //사진 저장
        const form = new FormData();
        for (const i of Object.keys(this.fileArray)) {
          form.append("file" + i, this.fileArray[i]);
        }

        // form.append('file', this.file);
        form.append("no", this.photoitem.no);

        if (this.fileArray.length < 1) {
          this.$toast.show("파일을 선택해주세요.", {
            type: "warning",
            position: "top-right",
          });
          return;
        }
        manageService
          .photoUpload(form, {
            header: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.$toast.show("사진이 등록되었습니다.", {
              type: "success",
              position: "top-right",
            });
            this.getMgmtAllList(this.form.grade);
            $("#memberPhotoModal").modal("hide");
          });
      },
      //모달 닫기
      modalClose() {
        this.file = "";
        this.fileArray = [];
        this.formreset(); //초기화
      },
      // 신규 추가
      AddMember() {
        this.isNew = true;

        $("#memberAddModal").modal("show");
      },
      //전체 리스트 가져오기
      getMgmtAllList(item) {

        console.log("getMgmtAllList",this.selectleader);

        var tempgrade = "";
        if (item == null) {
          tempgrade = this.form.grade;
        } else {
          tempgrade = item;
        }

        const idata = {
          sector: "30",
          grade: tempgrade,
          teacherid: this.selectleader,
          classid: this.classid,
        };

        manageService
          .getAllList(idata)
          .then((response) => {
            //this.manageLists = response.data;
           // console.log("getAllList", idata);
            this.manageLists = response.data.slice(0, this.page);
            
            //this.manageLists = response.data;
            this.manageListLimit = response.data;
            this.pageTotalCount = Math.ceil(response.data.length / this.page);
          })
          .catch((e) => {
            console.log(e);
          });
      },
      alertMsg(msg) {
        this.$toast.show(msg, {
          type: "warning",
          position: "top-right",
        });
      },
      //팀원 등록
      setMember(division) {

        console.log(division);

        var jsondata = null;
        //e.preventDefault();
        //   if(division == "1"){
        //   var object = {};
        //   var fform = document.getElementById("addMemberform");
        //   var form = new FormData(fform);
        //   form.forEach(function (value, key) {
        //     object[key] = value;
        //     console.log(key, value);
        //   });
        //   jsondata = JSON.stringify(object);

        // } else {

        jsondata = {
          groupid: this.form.groupid,
          gradediv: this.form.gradediv,
          name: this.form.name,
          sex: this.form.sex,
          birthday: this.form.birthday,
          phone: this.form.phone,
          parentphone: this.form.parentphone,
          address: this.form.address,
          rmk: this.form.rmk,
          teacherid: this.form.teacherid,
          school: this.form.school,
          div: division,
          no: this.form.no,
          classid: this.classid,
          grade: this.form.groupid + this.form.gradediv,
        };      

        // if (this.form.teacherid == undefined) {
        //   this.alertMsg("선생님을 선택하세요.");
        //   return;
        // }
        // if (this.form.groupid == undefined) {
        //   this.alertMsg("학년을 선택하세요.");
        //   return;
        // }
        // if (this.form.gradediv == undefined) {
        //   this.alertMsg("반을 선택하세요.");
        //   return;
        // }
        if (this.form.name.length < 2) {
          this.alertMsg("이름을 입력하세요.");
          return;
        }
        if (this.form.sex == undefined) {
          this.alertMsg("성별을 선택하세요.");
          return;
        }
        if (this.form.name.birthday < 3) {
          this.alertMsg("출생년도만 입력하세요.");
          return;
        }
        // if (this.form.name.phone < 5) {
        //   this.alertMsg("연락처를 입력하세요.");
        //   return;
        // }
        // if (this.form.name.parentphone < 5) {
        //   this.alertMsg("학부모 연락처를 입력하세요.");
        //   return;
        // }

        manageService
          .register(jsondata)
          .then(() => {
            // console.log(response.data);
            this.$toast.show("정상적으로 등록되었습니다.", {
              type: "success",
              position: "top-right",
            });
            //this.getMgmtAllList(this.form.groupid + this.form.gradediv);
            $("#memberAddModal").modal("hide");
            this.formreset(); //초기화
          })
          .catch((e) => {
            console.log(e);
            this.$toast.show("등록되지 않았습니다.", {
              type: "warning",
              position: "top-right",
            });
          });
      },

      UpdateMember(item) {
        //수정

        this.form.groupid = item.groupid;
        this.form.gradediv = item.grade.substr(1, 1);
        this.form.name = item.name;
        this.form.sex = item.sex;
        this.form.birthday = item.birthday;
        this.form.phone = item.phone;
        this.form.parentphone = item.parentphone;
        this.form.address = item.address;
        this.form.rmk = item.rmk;
        this.form.teacherid = item.teacherid;
        this.form.school = item.school;
        this.form.no = item.no;

        this.isNew = false;
        $("#memberAddModal").modal("show");        
      },
      formreset() {
        this.form.groupid = "";
        this.form.gradediv = "";
        this.form.name = "";
        this.form.sex = "";
        this.form.birthday = "";
        this.form.phone = "";
        this.form.parentphone = "";
        this.form.address = "";
        this.form.rmk = "";
        this.form.teacherid = "";
        this.form.school = "";
        this.form.no = "";
      },
    },
    created() {
      this.ReaderGetListAll();  //셀리더 출력
      //this.getMgmtAllList(this.form.grade);
      // if (this.role > 2) {
      //   this.getMgmtAllList(this.form.grade);
      // }

      // this.getTeacher(this.form.teacherid);
      // this.getGradeGroupList();
    },
  };
</script>
